import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { supabase } from '../client';
import { showToastFailure, showToastSuccess } from '../services/toast';
import { useLoading } from '../contexts/LoadingContext';
import { getFormattedDate } from '../utils/formattedDate';
import { SelectDatepicker } from 'react-select-datepicker';


// Define the schema using yup
const schema = yup.object({
    first_name: yup.string().required('First name is required'),
    last_name: yup.string().required('Last name is required'),
    email: yup.string().email('Invalid email address').required('Email is required'),
    password: yup.string()
        .required('Password is required')
        .min(6, 'Password must be at least 6 characters long')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/\d/, 'Password must contain at least one number')
        .matches(/[@$!%*?&]/, 'Password must contain at least one special character'),
    dob: yup.date()
        .required('Date of birth is required'),
    confirm_password: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Confirm password is required'),
    phone: yup.string()
        .required('Phone number is required')
        .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
});



const Registration = () => {
    const { showLoader, hideLoader } = useLoading();
    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (formData) => {
        try {
            showLoader();

            // Check if email or phone already exists
            const { data: existingUser, error: selectError } = await supabase
                .from('users')
                .select('id')
                .or(`email.eq.${formData.email},phone.eq.${formData.phone}`)
                .single();

            if (selectError && selectError.code !== 'PGRST116') {
                throw selectError;
            }

            if (existingUser) {
                throw new Error("Email or phone number already exists.");
            }

            // Sign up the user and handle response
            const { data, error: authError } = await supabase.auth.signUp({
                email: formData.email,
                password: formData.password,
                data: {
                    phone: formData.phone
                }
            });

            if (authError) {
                throw authError;
            }

            if (data?.user) {
                // Save the user details in the users table
                const { error: userError } = await supabase
                    .from('users')
                    .insert([
                        {
                            user_id: data.user.id,
                            first_name: formData.first_name,
                            last_name: formData.last_name,
                            email: formData.email,
                            phone: formData.phone,
                            is_active: true,
                            role: 'user',
                            dob: formData.dob,
                            created_at: getFormattedDate(new Date())
                        },
                    ]);

                if (userError) {
                    throw userError;
                }

                // Success message or further actions
                showToastSuccess("User registered successfully, please continue check your registered mail box to check your email");
                reset();
            }

        } catch (error) {
            showToastFailure(error.message);
        } finally {
            hideLoader();
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <Controller
                name="first_name"
                defaultValue=''
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        label="First Name"
                        fullWidth
                        margin="normal"
                        error={!!errors.first_name}
                        helperText={errors.first_name?.message}
                    />
                )}
            />

            <Controller
                name="last_name"
                defaultValue=''
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        label="Last Name"
                        fullWidth
                        margin="normal"
                        error={!!errors.last_name}
                        helperText={errors.last_name?.message}
                    />
                )}
            />

            <Controller
                name="email"
                defaultValue=''
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        label="Email"
                        fullWidth
                        margin="normal"
                        error={!!errors.email}
                        helperText={errors.email?.message}
                    />
                )}
            />

            <Controller
                name="phone"
                defaultValue=''
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        label="Phone"
                        fullWidth
                        margin="normal"
                        error={!!errors.phone}
                        helperText={errors.phone?.message}
                        inputProps={{
                            maxLength: 10, // Limit input to 10 characters
                        }}
                    />
                )}
            />

            <div className="mb-4 border border-gray-300 rounded-lg p-4">
                <label className="block text-sm font-medium text-gray-700">Date of Birth</label>

                <Controller
                    name="dob"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                        <SelectDatepicker
                            maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 16))}
                            selectedDate={field.value ? new Date(field.value) : null} // Ensure selectedDate is a valid Date object or null
                            onDateChange={(date) => {
                                // Check if the selected date is valid and different from the current value
                                if (date instanceof Date && !isNaN(date.getTime()) && date.getTime() !== new Date(field.value).getTime()) {
                                    field.onChange(date); // Update the form field with the valid date
                                }
                            }}
                        />
                    )}
                />

                {errors.dob && <p className="text-red-600 text-sm">{errors.dob.message}</p>} {/* Display error message */}
            </div>


            <Controller
                name="password"
                defaultValue=''
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        label="Password"
                        type="password"
                        fullWidth
                        margin="normal"
                        error={!!errors.password}
                        helperText={errors.password?.message}
                    />
                )}
            />

            <Controller
                name="confirm_password"
                defaultValue=''
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        label="Confirm Password"
                        type="password"
                        fullWidth
                        margin="normal"
                        error={!!errors.confirm_password}
                        helperText={errors.confirm_password?.message}
                    />
                )}
            />

            <Button type="submit"
                sx={{
                    backgroundColor: '#756394',
                    color: '#FFFFFF',
                    marginTop: '10px'
                }} variant="contained"
                fullWidth>
                Register
            </Button>
        </form>
    );
};

export default Registration;
