import { format } from 'date-fns';

export const getFormattedDate = (date) => {
    const currentDate = format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX");
    return currentDate;
}

export const getDDMMYYYYWithTimeFormat = (date) => {
    if(date){
        const currentDate = format(new Date(date), "dd-MM-yyyy hh:mm a");
        return currentDate;
    }
   return date
}