import moment from 'moment';
import React from 'react';

const TicketCard = ({ title, description, amount, gstAmount, ticketsPurchased, paymentStatus, createdAt, paymentId }) => {
    return (
        <div className="bg-white shadow-lg rounded-lg p-4 mb-4">
            <h3 className="text-lg font-semibold mb-2">{title}</h3>
            <p className="text-gray-700 mb-2">{description}</p>
            <p className="text-gray-700 mb-2">Amount: ₹ {amount}</p>
            <p className="text-gray-700 mb-2">GST Amount: ₹ {gstAmount}</p>
            <p className="text-gray-700 mb-2">Tickets Purchased: {ticketsPurchased}</p>
            <p className="text-gray-700 mb-2">Payment Status: {paymentStatus}</p>
            <p className="text-gray-500 text-sm">Ordered at: { moment(createdAt).format('DD-MM-YYYY HH:mm:ss')} </p>
            <p className="text-gray-500 text-sm">Payment ID: {paymentId}</p>
        </div>
    );
};

export default TicketCard;
