import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactModal from 'react-modal';
import StarRating from './StarRating';
import {
  Instagram as InstagramIcon,
  Language as LanguageIcon,
  Google as GoogleIcon
} from '@mui/icons-material';
import orders from '../services/ordersService'
import { useLoading } from '../contexts/LoadingContext';
import { useUser } from '../contexts/UserContext';
import { showToastFailure } from '../services/toast';

const { completeSession } = orders;
const FeedbackModal = ({ order, isOpen, onClose }) => {
  const navigate = useNavigate();
  const [ambianceRating, setAmbianceRating] = useState(0);
  const [gamesRating, setGamesRating] = useState(0);
  const [overAllRating, setOverAllRating] = useState(0);
  const [additionalFeedback, setAdditionalFeedback] = useState('');
  const { showLoader, hideLoader } = useLoading()
  const { user } = useUser();

  const handleSubmit = () => {

   
    if (
      ambianceRating > 0 ||
      gamesRating > 0 ||
      overAllRating > 0 ||
      additionalFeedback.trim() !== ''
    ) {
      const feedbackData = {
        ambianceRating,
        gamesRating,
        overAllRating,
        additionalFeedback,
        sessionId: order?.sessions[0]?.id,
        game_status: 'completed',
        userId: user?.id,
        feedback: additionalFeedback
      }

      completeSession(feedbackData, showLoader, hideLoader);
      // Handle submission of feedbackData
      onClose();
      navigate('/pass');
    } else {
      showToastFailure('Please fill out at least one of the feedback fields before submitting.')
    }
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Feedback Modal"
      className="bg-white p-4 md:p-6 lg:p-8 rounded-lg shadow-lg max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg mx-auto my-8 overflow-auto"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      
      <div className="flex justify-between items-center mb-2">
    <h2 className="text-lg md:text-xl lg:text-2xl font-semibold text-gray-800">
      End Session
    </h2>
    <button
      onClick={onClose}
      className="text-gray-400 hover:text-gray-600 focus:outline-none"
    >
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>
  </div>



      <div className="mb-2">
        <label className="block text-gray-700">Ambiance</label>
        <StarRating rating={ambianceRating} setRating={setAmbianceRating} />
      </div>
      <div className="mb-2">
        <label className="block text-gray-700">Games</label>
        <StarRating rating={gamesRating} setRating={setGamesRating} />
      </div>
      <div className="mb-2">
        <label className="block text-gray-700">Overall</label>
        <StarRating rating={overAllRating} setRating={setOverAllRating} />
      </div>
      <div className="mb-2">
        <label className="block text-gray-700">Additional Feedback</label>
        <textarea
          value={additionalFeedback}
          onChange={(e) => setAdditionalFeedback(e.target.value)}
          className="w-full p-2 border rounded-lg"
          rows="3"
        />
      </div>

      {/* Website Redirection */}
      <div className="mt-4">
        <h3 className="text-md md:text-lg lg:text-xl font-semibold text-gray-800 mb-2">Visit Our Link</h3>
        <a href="https://www.bombayboardgameclub.com" target="_blank" rel="noopener noreferrer" className="flex items-center text-blue-500 hover:underline">
          <LanguageIcon className="mr-2" />
          Website
        </a>

        <a href="https://www.instagram.com/bombayboardgameclub/?hl=en" target="_blank" rel="noopener noreferrer" className="flex items-center text-blue-500 mt-2 hover:underline">
          <InstagramIcon className="mr-2" />
          Instagram
        </a>

        <a href="https://maps.app.goo.gl/HuDZDT3TZLWgvQdT7" target="_blank" rel="noopener noreferrer" className="flex items-center text-blue-500 mt-2 hover:underline">
          <GoogleIcon className="mr-2" />
          Google Maps 
        </a>
      </div>

      <div className="flex flex-col md:flex-row justify-end space-y-4 md:space-x-4 mt-6">

        <button
          className="bg-customPurple text-white px-4 py-2 rounded-md hover:bg-blue-700"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </ReactModal>


  );

};

export default FeedbackModal;
