const Footer = () => {
    return (
      <footer className="fixed bottom-0 left-0 w-full bg-customPurple text-white py-4 text-center">
        <div className="flex justify-center space-x-4 mt-2">
          <a href="mailto:registration@bombayboardgameclub.com"  className="hover:underline text-sm">Write to Us</a>
          {/* <a href="/faq" className="hover:underline text-sm">FAQ</a>
          <a href="/privacy-policy" className="hover:underline text-sm">Privacy Policy</a>
          <a href="/terms-of-service" className="hover:underline text-sm">Terms of Service</a> */}
        </div>
      </footer>
    );
  };

  export default Footer