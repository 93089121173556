import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../client';
import { showToastFailure, showToastSuccess } from '../services/toast';
import { useLoading } from '../contexts/LoadingContext';
import { useUser } from '../contexts/UserContext';

// Validation schema
const schema = yup.object({
    password: yup.string()
        .required('Password is required')
        .min(6, 'Password must be at least 6 characters long')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/\d/, 'Password must contain at least one number')
        .matches(/[@$!%*?&]/, 'Password must contain at least one special character'),
    confirmPassword: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
});

const ResetPassword = () => {
    const navigate = useNavigate();
    const [accessToken, setAccessToken] = useState(null);
    const { showLoader, hideLoader } = useLoading();
    const { clearUser } = useUser()

    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        // Extract token from hash fragment
        const hash = window.location.hash;
        const params = new URLSearchParams(hash.substring(1)); // Remove the '#' from the hash
        const token = params.get('access_token');
        
        if (token) {
            setAccessToken(token);
            sessionStorage.setItem('accessToken', token); // Persist token in sessionStorage
        } else {
            // Try to get token from sessionStorage if hash doesn't have it
            const storedToken = sessionStorage.getItem('accessToken');
            if (storedToken) {
                setAccessToken(storedToken);
            }
        }
    }, [navigate]);

    const onSubmit = async (formData) => {

        showLoader();
        try {
            // Extract user ID from token
           const { data: { user } } = await supabase.auth?.getUser(accessToken);
            if (!user) { 
                showToastFailure('User not found.');
                return;
            }

            // Update password
            const {  error } = await supabase.auth.updateUser({
                password: formData.password,
              });

            if (error) {
                throw new Error(error.message);
            } else {
                showToastSuccess('Password updated successfully!');
                sessionStorage.clear();
                localStorage.removeItem('cipher')
                clearUser();
                navigate('/auth');
            }
        } catch (error) {
            showToastFailure(error.message || 'An unexpected error occurred.');
        } finally {
            hideLoader();
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 p-5">
            <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <TextField
                        {...field}
                        label="New Password"
                        type="password"
                        fullWidth
                        margin="normal"
                        error={!!errors.password}
                        helperText={errors.password?.message}
                    />
                )}
            />
            
            <Controller
                name="confirmPassword"
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <TextField
                        {...field}
                        label="Confirm Password"
                        type="password"
                        fullWidth
                        margin="normal"
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword?.message}
                    />
                )}
            />

            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                    backgroundColor: '#756394',
                    color: '#FFFFFF',
                    marginTop: '10px',
                }}
            >
                Set New Password
            </Button>
        </form>
    );
};

export default ResetPassword;
