import React from 'react';

const PassCard = ({ pass, onPassClick, bgColor = 'bg-white', textColor = 'text-gray-800' , buttonBgColor = 'bg-customPurple',
  buttonTextColor = 'text-white'}) => {
  return (
    <div
    className={`p-4 rounded-lg shadow-md flex flex-col space-y-2 hover:shadow-lg transition-shadow duration-300 ${bgColor}`}
  >
    <h3 className={`text-lg font-semibold ${textColor}`}>{pass?.name}</h3>
    <p className={`text-gray-600 text-sm ${textColor}`}>
      {pass?.description}
    </p>
    <button
      className={`self-end ${buttonBgColor} ${buttonTextColor} font-medium px-3 py-1 rounded-md hover:bg-blue-700 transition duration-200`}
      onClick={() => onPassClick(pass)}
    >
      View Tickets
    </button>
  </div>
  );
};

export default PassCard;
