import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import the carousel styles
import { supabase } from '../client';
import { useLoading } from '../contexts/LoadingContext';

const BannerCarousel = () => {
  const [banners, setBanners] = useState([]);
  const { showLoader, hideLoader } = useLoading()

  // Fetch banners from Supabase
  const fetchImages = async () => {
    try {
      showLoader()
      // Fetch list of files from the 'banners' folder inside the 'images' bucket
      const { data: files, error } = await supabase
        .storage
        .from('images') // The name of the Supabase bucket
        .list('banners'); // Folder path inside the bucket
  
      if (error) {
        hideLoader()
        return;
       
      }
  
      if (files && files?.length > 0) {
  
        // Fetch public URLs for all files in parallel
        const urlPromises = files.map(async (file) => {
          try {
            const { data: publicUrlData, error: urlError } = await supabase
              .storage
              .from('images')
              .getPublicUrl(`banners/${file.name}`);
  
            if (urlError) {
              console.error(`Error fetching public URL for ${file.name}:`, urlError.message);
              return {
                ...file,
                transformedImageUrl: null,
              };
            }
  
            return {
              ...file,
              transformedImageUrl: publicUrlData?.publicUrl
                ? `${publicUrlData.publicUrl}?width=100&height=100` // Adjust size as needed
                : null,
            };
          } catch (err) {
            console.error(`Error processing file ${file.name}:`, err.message);
            return {
              ...file,
              transformedImageUrl: null,
            };
          }
        });
  
        // Wait for all URL fetching to complete
        const filesWithUrls = await Promise.all(urlPromises);
        setBanners(filesWithUrls);
      }
    } catch (err) {
        return
    }finally{
      hideLoader()
    }
  };

  useEffect(() => {
    fetchImages();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Carousel
      autoPlay
      infiniteLoop
      showArrows={true} 
      interval={4000}
      showThumbs={false}
      showStatus={false}
    >
      {banners?.map((banner, index) => (
        <div key={index}>
          <img src={banner.transformedImageUrl} alt={`Banner ${index}`} />
        </div>
      ))}
    </Carousel>
  );
};

export default BannerCarousel;
