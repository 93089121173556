import React, { useEffect, useState } from 'react';
import moment from 'moment';
import FeedbackModal from '../components/FeedbackModal';
import orderService from '../services/ordersService'
import { useUser } from '../contexts/UserContext';
import { useLoading } from '../contexts/LoadingContext';

const { getOrderByUserId } = orderService;

const AccessActivePassScreen = () => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useUser();
  const { showLoader, hideLoader } = useLoading();
  const [history, setHistory] = useState([]);
  const [timers, setTimers] = useState({});

  useEffect(() => {
    const interval = setInterval(() => {
      const updatedTimers = history?.reduce((acc, activePass) => {
        const remainingTime = calculateRemainingTime(activePass?.created_at, activePass?.pass_details?.max_hours_allowed);
        const completionTime = calculateCompletionTime(activePass?.created_at, activePass?.pass_details?.max_hours_allowed);

        acc[activePass.id] = {
          remainingTime,
          completionTime,
        };

        return acc;
      }, {});

      setTimers(updatedTimers);
    }, 1000);

    return () => clearInterval(interval);
  }, [history]);



  useEffect(() => {
    const fetchHistory = async () => {
      if (user?.id) {
        const fetchedPasses = await getOrderByUserId(user?.id, showLoader, hideLoader);
        setHistory(fetchedPasses);
      }
    };
    fetchHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);


  const handleFeedbackClick = (pass) => {
    setSelectedOrder(pass);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedOrder(null);
  };

  const calculateRemainingTime = (createdAt, maxHoursAllowed) => {
    const now = moment();
    const endTime = moment(createdAt).add(maxHoursAllowed, 'hours');
    const duration = moment.duration(endTime.diff(now));

    if (duration.asSeconds() <= 0) {
      return 'Game Completed';
    }

    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return `${hours}h ${minutes}m ${seconds}s`;
  };

  const calculateCompletionTime = (createdAt, maxHoursAllowed) => {
    return moment(createdAt).add(maxHoursAllowed, 'hours').format('DD-MM-YYYY HH:mm:ss');
  };

  return (
    <div className="flex flex-col items-center min-h-screen w-full bg-gray-100 px-6 py-10">
      <div className="w-full max-w-lg">
        {history?.length > 0 ? (
          <div className="grid grid-cols-1 gap-6 w-full">
            {history.map((activePass, index) => (
              <div
                key={index}
                className="bg-white p-6 rounded-lg shadow-md flex flex-col space-y-4 hover:shadow-lg transition-shadow duration-300"
              >
                <div className="flex">
                  <h3 className="text-xl font-semibold text-gray-800">{activePass?.pass_details?.title}</h3>
                </div>

                <div className='flex flex-col'>
                  {activePass?.sessions[0]?.game_status !== 'completed' ? (
                    <div>
                      <span
                        className={`block text-lg font-bold ${timers[activePass.id]?.remainingTime === 'Game Completed'
                          ? 'text-red-600'
                          : 'text-green-600'
                          }`}
                      >
                        {timers[activePass.id]?.remainingTime}
                      </span>
                      <span className="block mt-2 text-sm font-sm text-gray-600">
                        Completion Time: {timers[activePass.id]?.completionTime}
                      </span>
                    </div>
                  ) : (
                    <span className="text-sm text-red-600">
                      Game Completed
                    </span>
                  )}
                </div>

                <p className="text-gray-600 text-sm">{activePass?.pass_details?.description}</p>
                <div className="text-gray-700 text-sm space-y-2">
                  <p className="mb-2"><strong>Amount:</strong> {activePass?.amount}</p>
                  <p className="mb-2"><strong>GST:</strong> {activePass?.gst_amount}</p>
                  <p className="mb-2"><strong>Tickets Purchased:</strong> {activePass?.no_of_ticket_purchased}</p>
                  <p className="mb-2"><strong>Payment Status:</strong> {activePass?.payment_status ? 'Success' : 'Failed'}</p>
                  <p className="mb-2"><strong>Order At:</strong> {activePass?.created_at && moment(activePass.created_at).format('DD-MM-YYYY HH:mm:ss')}</p>
                  <p className="mb-2"><strong>Payment ID:</strong> {activePass?.payment_id}</p>
                  <p className="mb-2"><strong>Feedback:</strong> {activePass?.sessions[0]?.feedback || 'No feedback provided'}</p>
                </div>

                {activePass?.sessions[0]?.game_status !== 'completed' && (
                  <button
                    className="self-end bg-customPurple text-white font-medium px-4 py-2 rounded-md hover:bg-blue-700 transition duration-200"
                    onClick={() => handleFeedbackClick(activePass)}
                  >
                    End Session
                  </button>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center text-gray-500">
            <p>No active passes available.</p>
          </div>
        )}
      </div>

      {selectedOrder && (
        <FeedbackModal
          order={selectedOrder}
          isOpen={isModalOpen}
          onClose={handleModalClose}
        />
      )}
    </div>
  );
};

export default AccessActivePassScreen;
