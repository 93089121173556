import React from 'react';

const AboutUs = () => (
  <div className="bg-white p-8 rounded-lg shadow-lg">
    <h1 className="text-3xl font-bold mb-4">About Us</h1>
    <p className="mb-4">
      Welcome to AGA BOARD GAMES LLP! We are a passionate team of game enthusiasts dedicated to bringing the joy of board gaming to communities and individuals. Our goal is to create an engaging environment where people can experience fun, creativity, and strategy through the wide variety of games we offer.
    </p>

    <h2 className="text-xl font-semibold mb-2">Who We Are</h2>
    <p className="mb-4">
      AGA BOARD GAMES LLP, founded in 2023, operates from our cozy and vibrant venue located in Vile Parle West, Mumbai. With a mission to promote board gaming as a social activity, we offer a selection of games suitable for all ages. Our space is designed to provide a welcoming and enjoyable atmosphere for both casual players and competitive gamers.
    </p>

    <h2 className="text-xl font-semibold mb-2">Our Mission</h2>
    <p className="mb-4">
      Our mission is to cultivate a community of board game lovers who can come together to enjoy and learn new games. We believe that games have the power to foster connections, improve strategic thinking, and provide endless entertainment. Whether you’re here to play with friends, family, or make new connections, we’re here to ensure you have an amazing experience.
    </p>

    <h2 className="text-xl font-semibold mb-2">What We Offer</h2>
    <ul className="list-disc pl-5 mb-4">
      <li className="mb-2">A wide selection of classic and modern board games</li>
      <li className="mb-2">Friendly and knowledgeable staff to guide you through the games</li>
      <li className="mb-2">A comfortable and spacious environment for hosting game nights and events</li>
      <li className="mb-2">Special events, tournaments, and workshops for game enthusiasts of all levels</li>
    </ul>

    <h2 className="text-xl font-semibold mb-2">Why Choose Us?</h2>
    <p className="mb-4">
      At AGA BOARD GAMES LLP, we’re not just another game venue – we’re a community. Our customers return to us because of the unique and engaging experience we offer. Whether you're looking to explore a new hobby or you're a seasoned gamer, we have something for everyone. Come join us and discover the joy of board gaming.
    </p>

    <h2 className="text-xl font-semibold mb-2">Contact Us</h2>
    <p className="mb-4">
      If you have any questions or want to know more about our offerings, feel free to reach out to us:
    </p>
    <p className="mb-2">
      <strong>Phone:</strong> +91 98200 02546
    </p>
    <p className="mb-2">
      <strong>Address:</strong> Bombay Board Game Club, 206, Man Excellenza, SV Road, Irla, Vile Parle West, Mumbai, Maharashtra 400056
    </p>
  </div>
);

export default AboutUs;
