import React from 'react';

const StarRating = ({ rating, setRating }) => {
  const handleRating = (rate) => {
    setRating(rate);
  };

  return (
    <div className="flex space-x-1">
      {[1, 2, 3, 4, 5].map((star) => (
        <button
          key={star}
          className={`text-xl ${rating >= star ? 'text-yellow-500' : 'text-gray-300'}`}
          onClick={() => handleRating(star)}
        >
          ★
        </button>
      ))}
    </div>
  );
};

export default StarRating;
