import React, { useEffect, useState } from 'react';
import { useUser } from '../contexts/UserContext';
import { getGST, getSubPasses } from '../services/passService';
import { useLoading } from '../contexts/LoadingContext';
import { useLocation } from 'react-router-dom';
import { showToastFailure } from '../services/toast';
import { useNavigate } from 'react-router-dom';
import { getFormattedDate } from '../utils/formattedDate';
import { Link } from 'react-router-dom'


const PassDetailsList = () => {
  const navigate = useNavigate();
  const [selectedPass, setSelectedPass] = useState(null);
  const [subPasses, setSubPasses] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [taxRate, setTaxRate] = useState(null);
  const { user } = useUser();
  const { showLoader, hideLoader } = useLoading();
  const location = useLocation();

  useEffect(() => {
    const passId = location.state?.passId;

    const fetchPassDetails = async () => {

      if (passId) {
        try {
          const gstAmount = await getGST(showLoader, hideLoader);
          setTaxRate(gstAmount[0]?.gst);

          const fetchedPasses = await getSubPasses(passId, showLoader, hideLoader);

          const updatedPasses = fetchedPasses?.map((pass) => ({
            ...pass,
            tax: gstAmount,
          }));

          setSubPasses(updatedPasses);
        } catch (error) {
          showToastFailure('error fetching passes')
        }
      }
    };

    fetchPassDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);


  const provideDetails = async (pass) => {

    const amounts = calculatePaymentDetails(pass.amount, quantity);
    let orderData = {
      user_id: user?.id,
      pass_detail_id: pass.id,
      amount: amounts.subtotal,
      gst_amount: amounts?.taxAmount?.toFixed(2),
      created_at: getFormattedDate(new Date()),
      no_of_ticket_purchased: amounts?.quantity,
    };

    navigate('/confirm-payment', {
      state: {
        passDetails: pass,
        orderData
      },
    });
  }

  const calculatePaymentDetails = (baseAmount, quantity) => {
    const subtotal = baseAmount * quantity;
    const taxRatePercentage = taxRate;
    const taxAmount = subtotal * taxRatePercentage / 100;
    const totalAmount = subtotal + taxAmount;

    return { subtotal, taxRatePercentage, totalAmount, taxAmount, quantity };
  };


  return (
    <div className="min-h-screen bg-gray-100 py-10 px-4">
      <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">Available Passes</h1>
      {subPasses && subPasses.length > 0 ? (
        <div className="max-w-4xl mx-auto space-y-6">
          {subPasses?.map(pass => {
            const amounts = calculatePaymentDetails(pass.amount, selectedPass === pass.id ? quantity : 1);


            return (
              <div key={pass.id} className="bg-white rounded-lg shadow-lg p-6">
                <h2 className="text-2xl font-semibold text-gray-700 mb-2">{pass.title}</h2>
                <p className="text-gray-600 mb-4">{pass.description}</p>
                <div className="flex justify-between items-center mb-2">
                  <span className="text-lg font-medium text-gray-800">₹{pass.amount}</span>
                  <span className="text-sm text-gray-500">Max Hours: {pass.max_hours_allowed}</span>
                </div>
                <div className="flex justify-between items-center mb-4">
                  <span className={`px-3 py-1 rounded-full text-sm font-semibold ${pass.no_of_tickets > 0 ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'}`}>
                    {pass.no_of_tickets > 0 ? `${pass.no_of_tickets} Tickets Remaining` : 'Sold Out'}
                  </span>
                  {pass.no_of_tickets > 0 && (
                    <div className="flex items-center space-x-2">
                      <label htmlFor={`quantity-${pass.id}`} className="text-sm text-gray-600">Qty:</label>
                      <select
                        id={`quantity-${pass.id}`}
                        className="border border-gray-300 rounded p-1"
                        value={selectedPass === pass.id ? quantity : 1}
                        onChange={(e) => {
                          setSelectedPass(pass.id);
                          setQuantity(Number(e.target.value));
                        }}
                      >
                        {[...Array(pass.no_of_tickets).keys()].map((n) => (
                          <option key={n + 1} value={n + 1}>{n + 1}</option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
                {pass.no_of_tickets > 0 && (
                  <div className="mb-4">
                    <div className="flex justify-between text-sm text-gray-600 mb-1">
                      <span>Tax {taxRate} % :</span>
                      <span>₹{amounts.taxAmount?.toFixed(2)}</span>
                    </div>
                    <div className="flex justify-between text-lg font-semibold text-gray-800 mb-2">
                      <span>Total Amount:</span>
                      <span>₹{amounts.totalAmount?.toFixed(2)}</span>
                    </div>
                  </div>

                )}

                <div className="flex justify-end items-center mb-2">
                  <Link to="/cancellation-policy">
                    <span className="text-sm text-red-500 hover:underline">
                      Cancellation Policy
                    </span>
                  </Link>
                </div>

                {pass?.no_of_tickets > 0 && (
                  <button
                    onClick={() => provideDetails(pass)}
                    className="mt-4 w-full bg-customPurple text-white py-2 rounded-lg hover:bg-blue-700 transition duration-200"
                  >
                    Confirm Ticket
                  </button>
                )}
              </div>
            );
          })}:
        </div>
      ) : (

        <div className="text-center text-gray-500">
          No tickets available currently.
        </div>

      )}
    </div>
  );
};

export default PassDetailsList;
