import React from 'react';

const PrivacyPolicy = () => (
  <div className="bg-white p-8 rounded-lg shadow-lg">
    <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
    <p className="text-sm text-gray-500 mb-4">Last updated on Sep 18 2024</p>

    <p className="mb-4">
      At AGA BOARD GAMES LLP, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your information when you visit our website or use our services. Please read this policy carefully to understand our views and practices regarding your personal data.
    </p>

    <h2 className="text-xl font-semibold mb-2">1. Information We Collect</h2>
    <p className="mb-4">
      We may collect personal information that you provide directly to us when using our services. This includes your name, contact details, and payment information. Additionally, we may collect non-personal data automatically, such as your IP address, browser type, and browsing behavior.
    </p>

    <h2 className="text-xl font-semibold mb-2">2. Use of Information</h2>
    <p className="mb-4">
      We use your personal information to process transactions, communicate with you, improve our services, and for marketing purposes with your consent. Non-personal data is used to enhance your experience and to analyze traffic patterns on our website.
    </p>

    <h2 className="text-xl font-semibold mb-2">3. Sharing Your Information</h2>
    <p className="mb-4">
      We do not sell, trade, or rent your personal information to third parties. We may share your information with trusted third parties who assist us in operating our website, conducting our business, or servicing you, provided that those parties agree to keep this information confidential.
    </p>

    <h2 className="text-xl font-semibold mb-2">4. Data Security</h2>
    <p className="mb-4">
      We implement a variety of security measures to maintain the safety of your personal information. However, please note that no method of transmission over the Internet or method of electronic storage is 100% secure, and we cannot guarantee its absolute security.
    </p>

    <h2 className="text-xl font-semibold mb-2">5. Cookies</h2>
    <p className="mb-4">
      Our website may use "cookies" to enhance user experience. A cookie is a small file placed on your device for record-keeping purposes. You may choose to set your browser to refuse cookies, but this may limit your access to certain features of the website.
    </p>

    <h2 className="text-xl font-semibold mb-2">6. Your Rights</h2>
    <p className="mb-4">
      You have the right to access, correct, or delete the personal data we have about you. If you wish to exercise these rights or have any questions regarding our privacy practices, please contact us.
    </p>

    <h2 className="text-xl font-semibold mb-2">7. Changes to This Policy</h2>
    <p className="mb-4">
      We reserve the right to update or change this privacy policy at any time. Any changes will be posted on this page with an updated date. It is your responsibility to review this policy periodically.
    </p>

    <p className="text-sm text-gray-500 mt-6">If you have any questions about this privacy policy, you can contact us at: <strong>+91 98200 02546</strong></p>
  </div>
);

export default PrivacyPolicy;
