import React from 'react';
import { useUser } from '../contexts/UserContext';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';

const Header = () => {

    const { user } = useUser();
    const navigate = useNavigate();

    const handleProfileClick = () => {
        navigate('/profile')
    };

    return (
        <header className="bg-customPurple shadow-md py-4">
            <div className="container mx-auto flex items-center justify-between px-4">
                {/* Logo */}
                <div className="flex items-center">
            
                        <img
                            src="/app_logo_white.png" 
                            alt="App Logo"
                            className="w-10 h-10 mr-3"
                        />
                        <span className="text-base font-bold">Bombay Board Games Club</span>
            
                </div>

                {/* Profile Icon and Text */}
                {user && <div className="flex flex-col items-center cursor-pointer" onClick={handleProfileClick}>
                    <AccountCircleIcon className="text-white text-3xl mb-1 hover:text-gray-200 transition duration-300" />
                    <span className="text-white text-sm whitespace-nowrap">Profile</span>
                </div>}
            </div>
        </header>
    );
};

export default Header;
