import React, { useEffect, useState } from 'react';
import { useUser } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import orderService  from '../services/ordersService'
import TicketCard from '../components/TicketCard'
import { useLoading } from '../contexts/LoadingContext';


const {  getOrderByUserId } = orderService;

const ProfileScreen = () => {
  const { user, clearUser } = useUser();
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useLoading();
  const [history, setHistory] = useState([]);


  useEffect(() => {
    const fetchHistory = async () => {
      if(user?.id){
        const fetchedPasses = await getOrderByUserId(user?.id, showLoader, hideLoader);
        setHistory(fetchedPasses);
      }   
    };
    fetchHistory();
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);
  const handleLogout = () => {
    clearUser();
    navigate('/');
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center p-4">
      <div className="bg-white shadow-xl rounded-lg p-8 w-full max-w-lg mb-8">
        <div className="flex flex-col items-center">
          <img
            src={`https://ui-avatars.com/api/?name=${encodeURIComponent(user?.fullName)}&background=0D8ABC&color=fff&size=128`}
            alt="Profile"
            className="w-32 h-32 rounded-full mb-4"
          />
          <h2 className="text-2xl font-semibold text-gray-800 mb-2">{user?.fullName}</h2>
          <p className="text-gray-500 mb-1">{user?.email}</p>
          <p className="text-gray-500 mb-1">{user?.phone}</p>
          <button
            onClick={() => handleLogout()}
            className="bg-red-500 text-white py-2 mt-5 px-6 rounded-full hover:bg-red-600 transition duration-300"
          >
            Logout
          </button>
        </div>
      </div>

     {history?.length ? (
  <div className="bg-white shadow-xl rounded-lg p-8 w-full max-w-lg">
    <h2 className="text-2xl font-bold mb-6">My Purchased Tickets</h2>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {history.map((pass) => (
        <TicketCard
          key={pass.payment_id}
          title={pass.pass_details.title}
          description={pass.pass_details.description}
          amount={pass.amount}
          gstAmount={pass.gst_amount}
          ticketsPurchased={pass.no_of_ticket_purchased}
          paymentStatus={pass.payment_status ? 'Success' : 'Failed'}
          createdAt={pass.created_at && (pass.created_at)}
          paymentId={pass.payment_id}
        />
      ))}
    </div>
  </div>
) : (
  <div className="bg-white shadow-xl rounded-lg p-8 w-full max-w-lg text-center">
    <p className="text-lg font-medium">No tickets purchased yet</p>
  </div>
)} 

   
    </div>
  );
};

export default ProfileScreen;
