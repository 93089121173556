import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import orderService from '../services/ordersService';
import { useUser } from '../contexts/UserContext';
import { useLoading } from '../contexts/LoadingContext';
import { showToastFailure } from '../services/toast';
import { supabase } from '../client';
import { SelectDatepicker } from 'react-select-datepicker';
import ReactModal from 'react-modal';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const { createOrder } = orderService;

function ConfirmPayment() {
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = useUser();
    const [isNotesModalOpen, setNotesModalOpen] = useState(false);
    const { showLoader, hideLoader } = useLoading();

    const { passDetails, orderData } = location.state || {};

    const [ticketDetails, setTicketDetails] = useState([]);

    useEffect(() => {
        if(orderData.no_of_ticket_purchased >= 2){
            openModal();
        }
    }, [orderData.no_of_ticket_purchased ])

    const validateQRCode = async () => {
        try {
            const scannedQR = localStorage.getItem('scannedQR');
            showLoader();
            const { data: qrResponse, error } = await supabase.rpc('validate_qr', { qr_code: scannedQR });

            if (error || !qrResponse || !qrResponse.isValid || !qrResponse.data.is_active) {
                localStorage.removeItem('scannedQR');
                localStorage.removeItem('expirationTime');
                showToastFailure('bookings is not active currently, please contact store for more info')
                navigate("/");
                return;
            }

            return qrResponse;
        }
        catch (err) {
            showToastFailure('An unexpected error occurred.');
        } finally {
            hideLoader();
        }
    };

    // Handle changes for each ticket field dynamically
    const handleChange = (index, field, value) => {
        setTicketDetails((prevDetails) => {
            const newDetails = [...prevDetails];
            newDetails[index] = {
                ...newDetails[index],
                [field]: value,
            };
            return newDetails;
        });
    };

    const validateForm = () => {
        let isValid = true;

        ticketDetails?.forEach((ticket, index) => {
            // Check if any one field is filled
            const isAnyFieldFilled = ticket?.name || ticket?.dob || ticket?.email || ticket?.mobile;
            const isAllFieldsFilled = ticket?.name && ticket?.dob && ticket?.email && ticket?.mobile;

            if (isAnyFieldFilled && !isAllFieldsFilled) {
                // If any field is filled but not all, it's invalid
                isValid = false;
            }
        });

        return isValid;
    };


    const openModal = () => {
        setNotesModalOpen(true);
    };

    // Close Modal handler
    const closeModal = () => {
        setNotesModalOpen(false);
    };


    const handlePurchase = async () => {
        if (validateForm()) {


            const filteredTicketDetails = ticketDetails?.filter(item => item !== undefined && item !== null);
            const qrData = await validateQRCode();
            if (qrData?.isValid && qrData?.data?.is_active) {

                const totalAmt = (parseFloat(orderData?.gst_amount || 0) + parseFloat(orderData?.amount || 0)).toFixed(2)

                const options = {
                    key: process.env.REACT_APP_RAZOR_PAY,
                    amount: (totalAmt * 100).toFixed(2),
                    currency: "INR",
                    name: "Bombay Board Games Club",
                    description: `Purchase of ${passDetails?.title}`,
                    image: "/app_logo.jpg",
                    handler: async function (response) {
                        if (response.razorpay_payment_id) {
                            const checkoutData = { ...orderData, payment_id: response.razorpay_payment_id, payment_status: true, ticketDetails: filteredTicketDetails }
                            await createOrder(checkoutData, showLoader, hideLoader)
                            navigate('/pass')
                        } else {
                            const checkoutData = { ...orderData, payment_id: null, payment_status: false }
                            await createOrder(checkoutData, showLoader, hideLoader)
                        }
                    },
                    prefill: {
                        name: user?.fullName,
                        email: user?.email,
                        contact: user?.phone,
                    },
                    theme: {
                        color: "#756394",
                    },
                };

                const paymentObject = new window.Razorpay(options);
                paymentObject.open();
            } else {
                localStorage.removeItem('scannedQR');
                localStorage.removeItem('expirationTime');
                showToastFailure('bookings is not active currently, please contact store for more info')
                setTimeout(() => {
                    navigate("/");
                }, 3000)
            }
        } else {
            showToastFailure("Oops! It looks like some details are incomplete. Please fill in the name, email, phone, and date of birth for each person or leave the entire form blank if you'd like to skip it.");
        }

    };

    return (
        <>
            <div className="p-6">
                <div className="bg-white p-6 rounded-lg shadow-lg mb-6 border border-gray-200">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-3xl font-bold text-customPurple">{passDetails?.title}</h2>
                        <span className="text-sm  text-gray-500">Tickets: {orderData?.no_of_ticket_purchased}</span>
                    </div>

                    <p className="text-gray-600 text-lg mb-4">{passDetails?.description}</p>

                    <div className="grid grid-cols-2 gap-4">
                        <div className="p-4 bg-gray-100 rounded-lg">
                            <p className="text-xl font-semibold">Amount</p>
                            <p className="text-2xl font-bold text-green-600">₹{parseFloat(orderData?.amount || 0).toFixed(2)}</p>
                        </div>
                        <div className="p-4 bg-gray-100 rounded-lg">
                            <p className="text-xl font-semibold">GST</p>
                            <p className="text-2xl font-bold text-yellow-500">₹{parseFloat(orderData?.gst_amount || 0).toFixed(2)}</p>
                        </div>
                    </div>

                    <div className="p-4 bg-indigo-100 rounded-lg mt-6">
                        <p className="text-xl font-semibold text-customPurple">Total</p>
                        <p className="text-3xl font-bold text-indigo-700">
                            ₹{(parseFloat(orderData?.gst_amount || 0) + parseFloat(orderData?.amount || 0)).toFixed(2)}
                        </p>
                    </div>
                </div>



                <div className="flex flex-col h-full">
                    <form className="flex-1 overflow-y-auto">
                        {orderData.no_of_ticket_purchased - 1 > 0 &&
                            <>
                                {Array.from({ length: orderData.no_of_ticket_purchased - 1 }, (_, index) => (
                                    <div key={index} className="mb-4 p-4 border rounded-lg shadow-sm bg-white">
                                        <h3 className="text-xl font-semibold mb-4">Person {index + 1}</h3>

                                        <div className="mb-4">
                                            <label className="block text-sm font-medium text-gray-700">Full Name</label>
                                            <input
                                                type="text"
                                                value={ticketDetails[index]?.name || ''}
                                                onChange={(e) => handleChange(index, 'name', e.target.value)}
                                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                                                placeholder="Enter full name"
                                            />
                                        </div>


                                        <div className="mb-4 border border-gray-300 rounded-lg p-4">
                                            <label className="block text-sm font-medium text-gray-700">Date of Birth</label>

                                            <SelectDatepicker
                                                maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 16))}
                                                selectedDate={ticketDetails[index]?.dob}
                                                onDateChange={(e) => {
                                                    if (e) {
                                                        handleChange(index, 'dob', e)
                                                    }
                                                }}

                                            />
                                        </div>

                                        <div className="mb-4">
                                            <label className="block text-sm font-medium text-gray-700">Email</label>
                                            <input
                                                type="email"
                                                value={ticketDetails[index]?.email || ''}
                                                onChange={(e) => handleChange(index, 'email', e.target.value)}
                                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                                                placeholder="Enter email"
                                            />
                                        </div>

                                        <div className="mb-4">
                                            <label className="block text-sm font-medium text-gray-700">Mobile</label>
                                            <input
                                                type="tel"
                                                value={ticketDetails[index]?.mobile || ''}
                                                maxLength={10}
                                                onChange={(e) => handleChange(index, 'mobile', e.target.value)}
                                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                                                placeholder="Enter mobile number"
                                            />
                                        </div>
                                    </div>
                                ))}
                            </>
                        }
                    </form>
                </div>
            </div>
            <footer className="fixed bottom-0 left-0 w-full bg-customPurple text-white text-center">
                <div className="flex justify-center space-x-4">
                    <button
                        type="button"
                        onClick={handlePurchase}
                        className="w-auto bg-customPurple text-white  mb-2 mt-3 text-center rounded-lg hover:bg-blue-700 transition duration-200"
                    >
                        Purchase Ticket
                    </button>
                </div>
            </footer>

          
            <ReactModal 
                isOpen={isNotesModalOpen}
                onRequestClose={closeModal}
                contentLabel="Extra Ticket Information"
                className="bg-white p-10 border rounded-lg shadow-lg w-80 mx-auto relative"  
                overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
            >
                {/* Close Icon Button */}
                <IconButton 
                    onClick={closeModal} 
                    style={{ position: 'absolute', top: '0', right: '10px'}}  // Changed color to neutral grey
                >
                    <CloseIcon />
                </IconButton>

                {/* Modal content */}
                {orderData.no_of_ticket_purchased - 1 > 0 && (
                    <div>
                        <p className="text-customPurple font-bold">  {/* Changed text color to blue */}
                            You only need to provide details for the extra {orderData.no_of_ticket_purchased - 1} persons joining you. Please fill out the form or feel free to skip it if you prefer.
                        </p>
                    </div>
                )}

              
            </ReactModal>

            

        </>
    );
}

export default ConfirmPayment;
