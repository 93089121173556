// UserContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { supabase } from '../client';

// Create a Context for user
const UserContext = createContext();

// Custom hook to use the UserContext
export const useUser = () => useContext(UserContext);

// UserProvider component
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Retrieve user data from localStorage
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  // Example of updating user (e.g., on login)
  const updateUser = (userData) => {
    localStorage.setItem('user', JSON.stringify(userData));
    setUser(userData);
  };

  // Example of clearing user (e.g., on logout)
  const clearUser = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('cypher')
    sessionStorage.clear();
    supabase.auth.signOut();
    setUser(null);
  };

  return (
    <UserContext.Provider value={{ user, updateUser, clearUser }}>
      {children}
    </UserContext.Provider>
  );
};
