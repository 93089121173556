import React from 'react';
import { Link } from 'react-router-dom'; // Ensure you import Link for navigation

const Landing = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-gray-800 px-4">
      
      {/* By scanning message */}
      <div className="bg-purple-100 p-4 w-full text-center">
        <p className="text-customPurple text-sm font-semibold">
          By scanning the QR code, you accept our{" "}
          <Link to="/terms-and-conditions" className="underline">
            Terms & Conditions
          </Link>
          ,{" "}
          <Link to="/privacy-policy" className="underline">
            Privacy Policy
          </Link>
          , and{" "}
          <Link to="/cancellation-policy" className="underline">
            Cancellation/Refund Policy
          </Link>
          .
        </p>
      </div>

      <div className="bg-white p-8 rounded-lg shadow-lg text-center mt-6">
        <h1 className="text-3xl font-bold mb-4">Continue Your Booking</h1>
        <p className="text-lg mb-8">
          Use your mobile device to scan the QR code at Bombay Board Games Club.
        </p>
        <div className="flex justify-center mb-8">
          <img
            src="/app_logo.jpg"
            alt="QR Code"
            className="rounded-lg shadow-md"
          />
        </div>
        <p className="text-sm text-gray-500">
          Open your camera app on your phone and continue booking with QR.
        </p>
      </div>

      <div className="mt-8 text-center">
        <h5 className="text-lg font-semibold mb-4">Contact Information</h5>
        <p className="mb-2">
          <i className="fas fa-phone-alt mr-2"></i> +91 98200 02546
        </p>
        <p>
          <i className="fas fa-map-marker-alt mr-2"></i> Bombay Board Game Club, 206, Man Excellenza, SV road, Road, Irla, Vile Parle West, Mumbai, Maharashtra 400056
        </p>
      </div>

      {/* Add Redirection Links */}
      <div className="mt-8 text-center">
        <p className="mb-4">
          <Link to="/about-us" className="text-blue-500 hover:underline">
            About Us
          </Link>
        </p>
        <p className="mb-4">
          <Link to="/terms-and-conditions" className="text-blue-500 hover:underline">
            Terms & Conditions
          </Link>
        </p>
        <p className="mb-4">
          <Link to="/privacy-policy" className="text-blue-500 hover:underline">
            Privacy Policy
          </Link>
        </p>
        <p className="mb-4">
          <Link to="/cancellation-policy" className="text-blue-500 hover:underline">
            Cancellation/Refund Policy
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Landing;
