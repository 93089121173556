import { supabase } from "../client"
import { getFormattedDate } from "../utils/formattedDate";
import { showToastFailure, showToastSuccess } from "./toast";

const createOrder = async (orderData, startLoader, stopLoader) => {
  const { user_id, pass_detail_id, amount, gst_amount, payment_status, created_at, no_of_ticket_purchased, payment_id } = orderData;
  
  try {
    startLoader();

    // Insert query for the orders table
    const { data, error } = await supabase
      .from('orders')
      .insert([
        {
          user_id,
          pass_detail_id,
          amount,
          gst_amount,
          payment_status,
          created_at: getFormattedDate(new Date()),
          no_of_ticket_purchased,
          payment_id,
        }
      ])
      .select();

    if (error) {
      throw new Error('Failed to create order');
    }

    const insertedOrder = data[0];
    const { id: order_id } = insertedOrder;

    if (payment_status) {
      await createSession({ order_id, game_status: 'ongoing', created_at }, stopLoader);
      await calculateRemainingTickets(pass_detail_id, no_of_ticket_purchased);
      if(orderData.ticketDetails?.length){
        await createOrderMetaData({order_id, ticketDetails: orderData.ticketDetails } , stopLoader)
      }
     
    }

  } catch (error) {
    stopLoader();
    showToastFailure('Please contact the store if the payment is already processed, there is a technical issue');
  }
};

const calculateRemainingTickets = async (pass_detail_id, no_of_ticket_purchased) => {
 
  const { data } = await supabase
  .from('pass_details')
  .select('no_of_tickets')
  .eq('id', pass_detail_id)


  if (data && data.length && data[0].no_of_tickets >= no_of_ticket_purchased) {
    const remainingTickets = data[0].no_of_tickets - no_of_ticket_purchased;
   
    // Update the remaining tickets in the database
    const { error: updateError } = await supabase
      .from('pass_details')
      .update({ no_of_tickets: remainingTickets })
      .eq('id', pass_detail_id);

    if (updateError) {
      return
    }
  }
}

const createSession = async (sessionData , stopLoader) => {
  const { order_id, game_status } = sessionData;
  try {
    const { error } = await supabase
      .from('sessions')
      .insert([
        {
          order_id,
          game_status,
          created_at: getFormattedDate(new Date())
        }
      ]);

    if (error) {
      throw new Error('Failed to create session');
    }

    showToastSuccess('Your order is placed successfully');
    
  } catch (error) {
    showToastFailure('Payment was successful but there was a technical issue starting the session. Please contact the store.');
  }finally {
    stopLoader();
  }
};

const createOrderMetaData = async (metaData, stopLoader) => {
  const { order_id, ticketDetails } = metaData;
  try {
    // Create an array of objects to insert
    const dataToInsert = ticketDetails.map((ticket) => ({
      order_id,
      full_name: ticket.name,
      email: ticket.email,
      phone: ticket.mobile,
      dob: ticket.dob,
    }));

    // Insert the array into the order_metadata table
    const { error } = await supabase
      .from('order_metadata')
      .insert(dataToInsert);

    if (error) {
      throw new Error('Failed to insert order metadata');
    }

    showToastSuccess('Your order and ticket details were placed successfully');
    
  } catch (error) {
    showToastFailure('Payment was successful, but there was an issue saving the ticket details. Please contact the store.');
  } finally {
    stopLoader();
  }
}


const getOrderByUserId = async (userId, startLoader, stopLoader) => {
  try {
    startLoader();
    // Fetch orders with pass details and session data
    const { data, error } = await supabase
      .from('orders')
      .select(`
        *,
        pass_details:pass_detail_id (
          *
        ),
        sessions (
          *
        )
      `)
      .eq('user_id', userId)
      .order('created_at', { ascending: false });
      

    if (error) {
      throw new Error('Failed to fetch orders');
    }

    return data;
  } catch (error) {
    showToastFailure('Failed to fetch orders');
    console.error(error);
    return [];
  } finally {
    stopLoader();
  }
};

const completeSession = async (feedbackData, startLoader, stopLoader) => {
  startLoader();
  try {
    const { error } = await supabase
      .from('sessions')
      .update({
        feedback: feedbackData.feedback,
        game_status: feedbackData.game_status,
        updated_at: new Date().toISOString(),
        updated_by: feedbackData?.userId,
        feedback_ambiance: feedbackData?.ambianceRating,
        feedback_games: feedbackData?.gamesRating,
        feedback_overall: feedbackData?.overAllRating,
      })
      .match({ id: feedbackData.sessionId}); // Update the session with the specific ID

    if (error) {
      throw new Error('Failed to complete the session');
    }

    showToastSuccess('Session completed successfully');
  } catch (error) {
    showToastFailure('There was a technical issue completing the session. Please try again later.');
  } finally {
    stopLoader();
  }
};



// eslint-disable-next-line import/no-anonymous-default-export
export default {createOrder, getOrderByUserId, completeSession, createOrderMetaData}; 