import React from 'react';
import { UserProvider } from './UserContext';
import { LoadingProvider } from './LoadingContext';

// Create a combined provider component
const AppProviders = ({ children }) => {
  return (
    <LoadingProvider>
    <UserProvider>
        {children}¯
    </UserProvider>
    </LoadingProvider>
  );
};

export default AppProviders;
