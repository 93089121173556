import React, { useState } from 'react';
import Login from './Login';
import Register from './Register';

const AuthScreen = () => {
    const [activeTab, setActiveTab] = useState('login');

    const handleTabSwitch = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    {activeTab === 'login' ? 'Sign in to your account' : 'Create a new account'}
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="border-b border-gray-200">
                    <nav className="mb-px flex space-x-4" aria-label="Tabs">
                        <button
                            onClick={() => handleTabSwitch('login')}
                            className={`w-1/2 py-4 px-1 text-center text-sm font-medium ${activeTab === 'login' ? 'border-customPurple text-customPurple' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'} border-b-2 focus:outline-none`}
                        >
                            Login
                        </button>
                        <button
                            onClick={() => handleTabSwitch('register')}
                            className={`w-1/2 py-4 px-1 text-center text-sm font-medium ${activeTab === 'register' ? 'border-customPurple text-customPurple' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'} border-b-2 focus:outline-none`}
                        >
                            Register
                        </button>
                    </nav>
                </div>

                <div className="mt-6">
                    {activeTab === 'login' ? <Login /> : <Register />}
                </div>
            </div>
        </div>
    );
};

export default AuthScreen;
